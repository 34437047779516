import { Axios } from 'shared/utils';

export interface IUserCertificate {
  automated: boolean;
  certificate_type_id: number;
  created_at: Date;
  created_by: number;
  deleted_at: Date | null;
  deleted_by: number | null;
  expiration_date: Date;
  id: number;
  updated_at: Date;
  updated_by: number | null;
  user_id: number;
}

export interface IUserCertificateByType {
  id: number;
  name: string;
  certificates: IUserCertificate[];
}

export interface INewUserCertificate {
  automated: boolean;
  certificateTypeId: number;
  expirationMonth: number;
  expirationYear: number;
  trainedOn: Date;
  userId: number;
}

export interface ICertificateType {
  id: number;
  name: string;
}

export class CertificateService {
  public static async getUserCertificates(id: number): Promise<IUserCertificateByType[]> {
    return await Axios.get<{data: {certificates: IUserCertificateByType[]}}>(
      `/recertify/certificates/${id}`,
    ).then(response => response.data.data.certificates);
  }

  public static async getCertificateTypes(): Promise<ICertificateType[]> {
    return await Axios.get<{data: {certificateTypes: ICertificateType[]}}>(
      `/recertify/certificate-types`,
    ).then(response => response.data.data.certificateTypes);
  }

  public static async createCertificate({
    automated,
    certificateTypeId,
    expirationMonth,
    expirationYear,
    trainedOn,
    userId,
  }: INewUserCertificate): Promise<IUserCertificate[]> {
    return await Axios.post<{data: {certificates: IUserCertificate[]}}>(
      `/recertify/certificates/new`,
      {
        automated,
        certificateTypeId,
        expirationMonth,
        expirationYear,
        trainedOn,
        userId,
      },
    ).then(response => response.data.data.certificates);
  }

  public static async deleteCertificate(id: number): Promise<void> {
    await Axios.delete(
      `/recertify/certificates/${id}`,
    );
  }
}

export default CertificateService;
